import { cssTransition, ToastContainer } from 'react-toastify';
import { Outlet, useLocation } from 'react-router-dom';
import { /* StrictMode, */ useRef } from 'react';
import { useUpdateEffect } from 'react-use';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';

import styles from './MainLayout.module.scss';

const transition = cssTransition({
  enter: styles.noopEnterAnimation,
  exit: styles.exitAnimation,
  collapse: false,
});

const MainLayout = () => {
  const location = useLocation();
  const layoutRef = useRef<HTMLDivElement>(null);

  useUpdateEffect(() => {
    if (!layoutRef.current) return;

    smoothScrollIntoView(layoutRef.current, {
      duration: 0,
      block: 'start',
    });
  }, [location]);

  return (
    <>
      {/* <StrictMode> */}
      <>
        <div className={styles.mainLayout} ref={layoutRef}>
          <Outlet />
        </div>
      </>

      <ToastContainer className={styles.toastContainer} transition={transition}></ToastContainer>
      {/* </StrictMode> */}
    </>
  );
};

export default MainLayout;
