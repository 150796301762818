import { initializeApp } from 'firebase/app';
import { getFirestore, collection } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyC3xJK9MHNlX08JIREUBABZJAii1pT2pLg',
  authDomain: 'hashtagstorage.firebaseapp.com',
  projectId: 'hashtagstorage',
  storageBucket: 'hashtagstorage.appspot.com',
  messagingSenderId: '921504331193',
  appId: '1:921504331193:web:c335e5fe433df89800b268',
};

initializeApp(firebaseConfig);

const db = getFirestore();
const hashtagsCollection = collection(db, 'hashtags');
const filesCollection = collection(db, 'items');

export { db, hashtagsCollection, filesCollection };
