import LinkButton from 'components/LinkButton';
import { ReactComponent as IconArrowRight } from 'images/icons/arrowRight.svg';

import styles from './NotFound.module.scss';

const NotFound = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>The page you've requested can't be found!</h1>
        <h3 className={styles.subtitle}>Check out our homepage instead</h3>

        <LinkButton
          className={styles.button}
          to={'/'}
          type="primary"
          iconRight={<IconArrowRight />}
        >
          Go to the homepage
        </LinkButton>
      </div>
    </div>
  );
};

export default NotFound;
