export const SCRIPT_SRC = (document.currentScript as HTMLScriptElement).src;
const SCRIPT_ORIGIN = SCRIPT_SRC && new URL(SCRIPT_SRC).origin;

export const IS_EMBEDDED = SCRIPT_ORIGIN !== window.location.origin;
export const DEFAULT_ROOT_ELEMENT_ID = 'evechat-root';
export const DEFAULT_ROOT_ELEMENT_CLASSNAME = 'evechat-root';
export const BODY_EMBEDDED_CLASSNAME = 'evechat-embedded';
export const OFFLINE_STORE_NAME = 'evechat-store';

export const IS_PRODUCTION_ENV = /dev|localhost/.test(SCRIPT_ORIGIN) === false;

export const ROOT_ROUTE = '/';
export const EMAIL_CONFIRMATION_ROUTE = 'confirm-email';

export const DIGITS_REGEX = /\d+/g;
export const NON_DIGITS_REGEX = /[^0-9]/g;
export const PHONE_NUMBER_REGEX = /^\+?[0-9\s]*$/;

export const FILES_PER_FETCH_SIZE = 40;
