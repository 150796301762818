import { Link, To } from 'react-router-dom';
import { MouseEvent, ReactNode } from 'react';
import cn from 'classnames';
import isString from 'lodash/isString';

import Button from 'components/Button';

import { TButtonProps } from 'components/Button/Button';

import styles from './LinkButton.module.scss';

type TProps = TButtonProps & {
  children?: ReactNode;
  to?: To;
  onClick?: (event: MouseEvent) => void;
  openInNewTab?: Boolean;
};

const LinkButton = (props: TProps) => {
  const { children, to, className, type, iconRight, iconLeft, disabled, onClick, openInNewTab } =
    props;

  const shouldRenderAhref = isString(to) && to.includes?.('//');

  const button = (
    <Button
      type={type}
      iconRight={iconRight}
      iconLeft={iconLeft}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );

  return to && !disabled ? (
    shouldRenderAhref ? (
      <a
        href={to}
        className={cn(className, styles.linkButton)}
        target={openInNewTab ? '_blank' : ''}
        rel="noreferrer"
      >
        {button}
      </a>
    ) : (
      <Link className={cn(className, styles.linkButton)} to={to}>
        {button}
      </Link>
    )
  ) : (
    <span className={cn(className, styles.linkButton)}>{button}</span>
  );
};

export default LinkButton;
