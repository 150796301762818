import { QuerySnapshot } from 'firebase/firestore';

import { Nullable, TFiles } from 'types/interfaces';

export interface IInitialState {
  files: TFiles;
  fileQuerySnapshot: Nullable<QuerySnapshot>;
  hashtagUid: Nullable<string>;
  hasMore: boolean;
}

export const initialState: IInitialState = {
  files: null,
  fileQuerySnapshot: null,
  hashtagUid: null,
  hasMore: true,
};
