import { createContext } from 'react';

import { IInitialState, initialState } from 'utils/initialState';

type TContextValueSetter = (patch: Partial<IInitialState>) => void;

export type TContextType = [IInitialState, TContextValueSetter];

const GlobalStateContext = createContext<TContextType>([initialState, () => {}]);

export default GlobalStateContext;
