import { ReactNode } from 'react';
import { useSetState } from 'react-use';

import GlobalStateContext from 'utils/GlobalStateContext';
import { initialState } from 'utils/initialState';

interface IProps {
  children: ReactNode;
}

const GlobalStateProvider = (props: IProps) => {
  const { children } = props;

  const [state, setState] = useSetState(initialState);

  return (
    <GlobalStateContext.Provider value={[state, setState]}>{children}</GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;
