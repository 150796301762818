import {
  Route,
  BrowserRouter,
  Routes,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';
// import { useHashHistory } from 'use-hash-history';

import MainLayout from 'components/MainLayout';
import NotFound from 'components/NotFound';
import GlobalStateProvider from 'components/GlobalStateProvider';

import IndexPage from 'pages/IndexPage';

import { IS_EMBEDDED } from 'constants/common';
import { INDEX_ROUTE } from 'constants/routes';
import useSetVhSizeOnMobile from 'hooks/useSetVhSizeOnMobile';

const AppRouter = () => {
  // const history = useHashHistory({ hashRoot: '' });

  useSetVhSizeOnMobile();

  const routes = (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path={INDEX_ROUTE} element={<IndexPage />} />

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );

  return (
    <GlobalStateProvider>
      {/* {IS_EMBEDDED ? (
        // @ts-expect-error
        <HistoryRouter history={history}>{routes}</HistoryRouter>
      ) : ( */}
      <BrowserRouter>{routes}</BrowserRouter>
      {/* )} */}
    </GlobalStateProvider>
  );
};

export default AppRouter;
